import React, { useState, useEffect } from "react";
import { Box, Typography, Link, CircularProgress, styled } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useParams } from "react-router-dom";
import axios from "axios";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import urlImg from "../../../assets/profile_pic.jpeg";
import { FaExternalLinkAlt } from "react-icons/fa";
import Player from "../../../components/Player";
import { getAPIHandler } from "../../../ApiConfig/service";

const ReactApexChartAll = styled(Box)(({ theme }) => ({
  margin: "20px",
}));

const LineChart = styled(Box)(({ theme }) => ({
  "& .apexcharts-title-text": {
    color: "white",
  },
}));

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#e5f6fb",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  textAlign: "center",
  color: "#333",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "flex-start",
    padding: theme.spacing(4),
  },
}));

const ProfileImage = styled(Box)(({ theme }) => ({
  width: 150,
  height: 150,
  borderRadius: "50%",
  overflow: "hidden",
  border: `4px solid rgb(0, 168, 215)`,
  marginBottom: theme.spacing(2),
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const ProfileDetails = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
}));

const FollowButton = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 4),
  backgroundColor: "transparent",
  color: "#333",
  borderRadius: theme.shape.borderRadius,
  textAlign: "start",
  cursor: "pointer",
  transition: "background-color 0.3s",
}));

const ArtistRevenue = () => {
  const { id } = useParams();
  const [artist, setArtist] = useState(null);
  const [fairPrice, setFairPrice] = useState(null);
  const [revenueEstimates, setRevenueEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalListenersoOfArtist, setTotalListenersOfArtist] = useState(null);

  const ChartContainer = styled(Box)(({ theme }) => ({
    margin: theme.spacing(2),
    backgroundColor: "#e5f6fb",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(3),
    overflow: "hidden",
  }));

  const ChartTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  }));

  const LineChart = styled(Box)(({ theme }) => ({
    "& .apexcharts-title-text": {
      color: theme.palette.text.primary,
    },
    "& .apexcharts-xaxis-label": {
      color: theme.palette.text.secondary,
    },
    "& .apexcharts-yaxis-label": {
      color: theme.palette.text.secondary,
    },
  }));

  const formatFollowers = (count) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  };

  const fetchArtistUUID = async (id) => {
    const url = `https://customer.api.soundcharts.com/api/v2.9/artist/by-platform/spotify/${id}`;
    const headers = {
      "x-app-id": process.env.REACT_APP_SOUNDCHARTS_APP_ID,
      "x-api-key": process.env.REACT_APP_SOUNDCHARTS_API_KEY,
    };

    try {
      const response = await axios.get(url, { headers });

      if (response.data && response.data.object && response.data.object.uuid) {
        const uuid = response.data.object.uuid;
        return uuid;
      } else {
        throw new Error("UUID not found in response data");
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchTotalListeners = async (uuid) => {
    const platform = "spotify";
    const url = `https://customer.api.soundcharts.com/api/v2/artist/${uuid}/streaming/${platform}/listening`;
    const headers = {
      "x-app-id": process.env.REACT_APP_SOUNDCHARTS_APP_ID,
      "x-api-key": process.env.REACT_APP_SOUNDCHARTS_API_KEY,
    };

    try {
      const response = await axios.get(url, { headers });

      if (response.data.items && response.data.items.length > 0) {
        let maxValue = -Infinity;
        response.data.items.forEach((item) => {
          if (item.value !== undefined && typeof item.value === "number") {
            if (item.value > maxValue) {
              maxValue = item.value;
              setTotalListenersOfArtist(maxValue);
            }
          } else {
            console.warn(
              "Encountered invalid or undefined 'value' in an item:",
              item
            );
          }
        });

        if (maxValue !== -Infinity) {
          return maxValue;
        } else {
          throw new Error("No valid 'value' found in items array");
        }
      } else {
        console.error(
          "Error: Items array is empty or undefined in the response"
        );
        throw new Error("Items array is empty or undefined in the response");
      }
    } catch (error) {
      console.error("Error fetching total listeners:", error);
      throw error;
    }
  };

  const calculateFairPrice = (totalListeners) => {
    const discountRate = 0.1;
    const startYear = 2024;
    const endYear = 2030;
    const duration = endYear - startYear + 1;

    const estimatedCashFlows = [];
    let totalRevenue = 0;
    for (let i = 0; i < duration; i++) {
      const estimatedCashFlow = totalListeners * Math.pow(1 + discountRate, i);
      estimatedCashFlows.push(estimatedCashFlow);
      totalRevenue += estimatedCashFlow;
    }

    let fairPrice = 0;
    for (let i = 0; i < duration; i++) {
      fairPrice += estimatedCashFlows[i] / Math.pow(1 + discountRate, i + 1);
    }

    return {
      fairPrice: fairPrice.toFixed(2),
      totalRevenue: totalRevenue.toFixed(2),
      revenueEstimates: estimatedCashFlows.map((cashFlow, index) => ({
        year: startYear + index,
        revenue: cashFlow.toFixed(2),
      })),
    };
  };

  const getToken = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getSpotifyToken",
      });

      if (response && response.data && response.data.accessToken) {
        return response.data.accessToken;
      } else {
        throw new Error("Access token not found in API response");
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error;
    }
  };

  const fetchArtistData = async (id, token) => {
    try {
      const artistResponse = await axios.get(
        `https://api.spotify.com/v1/artists/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setArtist(artistResponse.data);

      const uuid = await fetchArtistUUID(id);

      if (uuid) {
        const totalListeners = await fetchTotalListeners(uuid);

        const { fairPrice, revenueEstimates } =
          calculateFairPrice(totalListeners);
        setFairPrice(fairPrice);
        setRevenueEstimates(revenueEstimates);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching artist data:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getToken();

        if (id.startsWith("spotify:artist:")) {
          const artistId = id.split(":")[2];
          await fetchArtistData(artistId, token);
        } else {
          setError(new Error("Invalid Spotify ID format"));
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const chartData = {
    categories: revenueEstimates.map((data) => data.year),
    series: [
      {
        name: "Revenue",
        data: revenueEstimates.map((data) => parseFloat(data.revenue)),
      },
    ],
  };

  const chartOptions = {
    series: chartData.series,
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "25%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: chartData.categories,
      title: {
        text: "Year-wise",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: "#333",
        },
      },
    },
    yaxis: {
      title: {
        text: "Revenue ($)",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: "#333",
        },
      },
      min: 0,
      tickAmount: 8,
      forceNiceScale: true,
      labels: {
        formatter: function (val) {
          return val === 0 ? "" : `$${Math.floor(val)}`;
        },
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: function (val) {
          return "$" + val;
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      labels: {
        colors: "#333",
        useSeriesColors: false,
      },
    },
  };

  return (
    <DashboardLayout sectionName="Revenue Management" title="Artist Revenue">
      <Box className="view-user-container">
        {loading && (
          <CircularProgress
            size={58}
            style={{ marginLeft: 550, marginTop: 300 }}
          />
        )}
        {error && <Typography>Error: {error.message}</Typography>}

        {!loading &&
          !error &&
          artist &&
          revenueEstimates &&
          revenueEstimates.length > 0 && (
            <Box className="view-user-content">
              <ProfileContainer>
                <ProfileImage>
                  <img src={artist.images[1]?.url} alt="Artist" />
                </ProfileImage>
                <ProfileDetails>
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    {artist.name}
                  </Typography>
                  <Typography>
                    Followers: {formatFollowers(artist.followers.total)}
                  </Typography>
                  <Typography>Popularity: {artist.popularity}</Typography>
                  <Typography>
                    Total Listeners: {totalListenersoOfArtist}
                  </Typography>

                  <Typography>Total Revenue Estimates: ${fairPrice}</Typography>

                  <FollowButton>
                    <Typography>
                      <Link
                        href={artist.external_urls.spotify}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="none"
                        sx={{ color: "inherit", textDecoration: "none" }}
                      >
                        Spotify <FaExternalLinkAlt />
                      </Link>
                    </Typography>
                  </FollowButton>
                </ProfileDetails>
              </ProfileContainer>

              <ChartContainer>
                <ChartTitle variant="h5">
                  Revenue Estimates Over Years
                </ChartTitle>
                <LineChart>
                  <ReactApexChart
                    options={chartOptions}
                    series={chartOptions.series}
                    type="bar"
                    height={350}
                  />
                </LineChart>
              </ChartContainer>
            </Box>
          )}
      </Box>
    </DashboardLayout>
  );
};

export default ArtistRevenue;
